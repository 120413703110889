<template>
  <v-row>
    <v-col md="12">
      
      <v-card>
        <v-card-title>
          <v-icon
            @click="pushRouteToView('profile')"
            class="mr-4"
            style="cursor: pointer"
          >
            mdi-arrow-left
          </v-icon>
          <v-icon class="mr-2">mdi-account</v-icon>
          Editar Perfil
        </v-card-title>
        <v-card-text>
          <v-form
            ref="formProfile"
            v-model="formIsValid"
            v-on:submit.prevent="submitEditProfile()"
          >
            <v-row>
              <v-col xl="6" lg="6" md="6" sm="12">
                <v-text-field
                  v-model="profile.cnpj"
                  outlined
                  label="CNPJ"
                  v-mask="['##.###.###/####-##']"
                  disabled
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="6" lg="6" md="6" sm="12">
                <v-text-field
                  v-model="profile.companyName"
                  :rules="textRules"
                  outlined
                  label="Razão Social"
                  v-uppercase
                />
              </v-col>
              <v-col xl="6" lg="6" md="6" sm="12">
                <v-text-field
                  v-model="profile.fantasyName"
                  :rules="textRules"
                  outlined
                  label="Nome Fantasia"
                  v-uppercase
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="6" lg="6" md="6" sm="12">
                <v-text-field
                  v-model="profile.phone"
                  outlined
                  label="Telefone"
                  :rules="telRules"
                  v-mask="['(##) ####-####', '(##) #####-####']"
                />
              </v-col>
              <v-col xl="6" lg="6" md="6" sm="12">
                <v-text-field
                  v-model="profile.email"
                  :rules="emailRules"
                  outlined
                  type="email"
                  label="E-mail"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="2" lg="2" md="3" sm="12">
                <v-text-field
                  v-model="profile.zipcode"
                  :rules="textRules"
                  outlined
                  label="Cep"
                  v-mask="['#####-###']"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="9" lg="9" md="9" sm="12">
                <v-text-field
                  v-model="profile.street"
                  :rules="textRules"
                  outlined
                  label="Rua"
                  v-uppercase
                />
              </v-col>
              <v-col xl="3" lg="3" md="3" sm="12">
                <v-text-field
                  v-model.number="profile.number"
                  :rules="textRules"
                  outlined
                  label="Número"
                  type="number"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="6" lg="6" md="6" sm="12">
                <v-text-field
                  v-model="profile.addressComplement"
                  outlined
                  label="Complemento"
                  v-uppercase
                />
              </v-col>
              <v-col xl="6" lg="6" md="6" sm="12">
                <v-text-field
                  v-model="profile.district"
                  outlined
                  label="Bairro"
                  v-uppercase
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="6" lg="6" md="6" sm="12">
                <v-text-field
                  v-model="profile.city"
                  :rules="textRules"
                  outlined
                  label="Cidade"
                  v-uppercase
                />
              </v-col>
              <v-col xl="6" lg="6" md="6" sm="12">
                <v-select
                  v-model="profile.state"
                  :items="states"
                  :rules="textRules"
                  label="Estado"
                  placeholder="Selecione um Estado"
                  outlined
                  item-text="state"
                  item-value="acronym"
                  v-uppercase
                ></v-select>
              </v-col>
              </v-row>
            
            <v-row>
              <v-col md="12" class="text-right">
                <v-spacer></v-spacer>
                <v-btn type="submit" color="success">
                  <v-icon left> mdi-content-save</v-icon>
                  Salvar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mask} from "vue-the-mask";
import {mapGetters} from "vuex";
import store from "@/store";

import {PROFILE_GET, PROFILE_UPDATE} from "@/store/actions/profile.type";
import {
  // PROFILE_REMOVE_WEEKDAY,
  // PROFILE_ADD_WEEKDAY,
  PROFILE_RESET_STATE,
} from "@/store/mutations/profile.type";
// import { WEEKDAYS_GET } from "@/store/actions/weekday.type";
import {CHANGE_PROGRESS} from "@/store/mutations/mutations.type";

export default {
  name: "EditProfile",
  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.commit(PROFILE_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    await store.commit(PROFILE_RESET_STATE);
    await store.dispatch(PROFILE_GET);
    // await store.dispatch(WEEKDAYS_GET);
    //todo weekdays nunca muda, carregar uma vez ao logar.

    return next();
  },
  directives: {mask},

  data: () => ({
    formIsValid: false,
    states: [
      {acronym: "AC", state: "ACRE"},
      {acronym: "AL", state: "ALAGOAS"},
      {acronym: "AP", state: "AMAPÁ"},
      {acronym: "AM", state: "AMAZONAS"},
      {acronym: "BA", state: "BAHIA"},
      {acronym: "CE", state: "CEARÁ"},
      {acronym: "DF", state: "DISTRITO FEDERAL"},
      {acronym: "ES", state: "ESPÍRITO SANTO"},
      {acronym: "GO", state: "GOIÁS"},
      {acronym: "MA", state: "MARANHÃO"},
      {acronym: "MT", state: "MATO GROSSO"},
      {acronym: "MS", state: "MATO GROSSO DO SUL"},
      {acronym: "MG", state: "MINAS GERAIS"},
      {acronym: "PA", state: "PARÁ"},
      {acronym: "PB", state: "PARAÍBA"},
      {acronym: "PR", state: "PARANÁ"},
      {acronym: "PE", state: "PERNAMBUCO"},
      {acronym: "PI", state: "PIAUÍ"},
      {acronym: "RJ", state: "RIO DE JANEIRO"},
      {acronym: "RN", state: "RIO GRANDE DO NORTE"},
      {acronym: "RS", state: "RIO GRANDE DO SUL"},
      {acronym: "RO", state: "RONDÔNIA"},
      {acronym: "RR", state: "RORAIMA"},
      {acronym: "SC", state: "SANTA CATARINA"},
      {acronym: "SP", state: "SÃO PAULO"},
      {acronym: "SE", state: "SERGIPE"},
      {acronym: "TO", state: "TOCANTINS"}
    ],
    textRules: [
      (v) => !!v || "Este campo é obrigatório",
      //v => v > 0 || "Esta campo deve ter um valor maior que zero."
    ],
    emailRules: [
      (v) => !!v || "E-mail é obrigatório",
      (value) => {
        if (typeof value !== "undefined" && value !== null) {
          if (value.length > 0) {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Informe um email válido";
          }
        }
        return false;
      },
    ],
    hourRules: [
      (v) => !!v || "Campo obrigatório",
      (value) => {
        if (typeof value !== "undefined" && value !== null) {
          if (value.length > 0) {
            const pattern = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/;
            return pattern.test(value) || "Informe um horário válido";
          }
        }
        return false;
      },
    ],
    telRules: [
      (v) => !!v || "Telefone é obrigatório",
      (value) => {
        if (typeof value !== "undefined" && value !== null) {
          if (value.length > 0) {
            const pattern = /(\(?\d{2}\)?\s)?(\d{4,5}-\d{4})$/;
            return pattern.test(value) || "Informe um telefone válido";
          }
        }
        return false;
      },
    ],
  }),

  methods: {
    submitEditProfile() {
      if (this.$refs.formProfile.validate()) {
        this.$store.commit(CHANGE_PROGRESS, true);
        this.$store
          .dispatch(PROFILE_UPDATE)
          .then(() => {
            this.$store.commit(CHANGE_PROGRESS, false);
            this.snackbar.snackbar = true;
            this.snackbar.color = "success";
            this.snackbar.text = "Editado com sucesso";
            this.$router.push({name: "profile"});
          })
          .catch(({response}) => {
            this.$store.commit(CHANGE_PROGRESS, false);
            this.snackbar.bloqueiaEnvio = false;
            this.snackbar.snackbar = true;
            this.snackbar.color = "error";
            this.snackbar.text = "Erro ao editar Perfil.";
            if (typeof response.data.message !== undefined) {
              this.snackbar.text =
                "Erro ao editar Perfil: " + response.data.message;
            }
          });
      } else {
        this.snackbar.snackbar = true;
        this.snackbar.color = "error";
        this.snackbar.text = "Preencha o formulário corretamente";
      }
    },

    pushRouteToView(route) {
      this.$router.push({name: route});
    },

    resetForm() {
      this.$refs.formProfile.resetValidation();
    },
  },
  computed: {
    ...mapGetters(["profile", "snackbar", "inProgress"]),
  },
};
</script>
